exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-templates-blog-article-article-js": () => import("./../../../src/templates/blog/article/article.js" /* webpackChunkName: "component---src-templates-blog-article-article-js" */),
  "component---src-templates-blog-blog-pagination-js": () => import("./../../../src/templates/blog/blogPagination.js" /* webpackChunkName: "component---src-templates-blog-blog-pagination-js" */),
  "component---src-templates-blog-category-blog-category-pagination-js": () => import("./../../../src/templates/blog/category/blogCategoryPagination.js" /* webpackChunkName: "component---src-templates-blog-category-blog-category-pagination-js" */),
  "component---src-templates-blog-tag-blog-tag-pagination-js": () => import("./../../../src/templates/blog/tag/blogTagPagination.js" /* webpackChunkName: "component---src-templates-blog-tag-blog-tag-pagination-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-portfolio-portfolio-js": () => import("./../../../src/templates/portfolio/portfolio.js" /* webpackChunkName: "component---src-templates-portfolio-portfolio-js" */),
  "component---src-templates-portfolio-work-work-js": () => import("./../../../src/templates/portfolio/work/work.js" /* webpackChunkName: "component---src-templates-portfolio-work-work-js" */)
}

